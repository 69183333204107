/* cyrillic-ext */
@font-face {
  font-family: '__Inter_375d66';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_375d66';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_375d66';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_375d66';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_375d66';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_375d66';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_375d66';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_375d66';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_375d66';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_375d66';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_375d66';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_375d66';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_375d66';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_375d66';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_375d66';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_375d66';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_375d66';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_375d66';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_375d66';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_375d66';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_375d66';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inter_Fallback_375d66';src: local("Arial");ascent-override: 90.49%;descent-override: 22.56%;line-gap-override: 0.00%;size-adjust: 107.06%
}.__className_375d66 {font-family: '__Inter_375d66', '__Inter_Fallback_375d66';font-style: normal
}.__variable_375d66 {--font-sans: '__Inter_375d66', '__Inter_Fallback_375d66'
}

@font-face {
font-family: '__newSpirit_341db0';
src: url(/_next/static/media/417ea65c0473c58f-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__newSpirit_341db0';
src: url(/_next/static/media/2a0c0940792da67a-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}@font-face {font-family: '__newSpirit_Fallback_341db0';src: local("Arial");ascent-override: 90.81%;descent-override: 25.77%;line-gap-override: 0.00%;size-adjust: 107.48%
}.__className_341db0 {font-family: '__newSpirit_341db0', '__newSpirit_Fallback_341db0'
}.__variable_341db0 {--font-serif: '__newSpirit_341db0', '__newSpirit_Fallback_341db0'
}

:root{--rt-color-white:#fff;--rt-color-dark:#222;--rt-color-success:#8dc572;--rt-color-error:#be6464;--rt-color-warning:#f0ad4e;--rt-color-info:#337ab7;--rt-opacity:0.9;--rt-transition-show-delay:0.15s;--rt-transition-closing-delay:0.15s}.core-styles-module_tooltip__3vRRp{left:0;opacity:0;pointer-events:none;position:absolute;top:0;will-change:opacity}.core-styles-module_fixed__pcSol{position:fixed}.core-styles-module_arrow__cvMwQ{background:inherit;position:absolute}.core-styles-module_noArrow__xock6{display:none}.core-styles-module_clickable__ZuTTB{pointer-events:auto}.core-styles-module_show__Nt9eE{opacity:var(--rt-opacity);transition:opacity var(--rt-transition-show-delay) ease-out}.core-styles-module_closing__sGnxF{opacity:0;transition:opacity var(--rt-transition-closing-delay) ease-in}.styles-module_tooltip__mnnfp{border-radius:3px;font-size:90%;padding:8px 16px;width:-webkit-max-content;width:max-content}.styles-module_arrow__K0L3T{height:8px;width:8px}[class*=react-tooltip__place-top]>.styles-module_arrow__K0L3T{transform:rotate(45deg)}[class*=react-tooltip__place-right]>.styles-module_arrow__K0L3T{transform:rotate(135deg)}[class*=react-tooltip__place-bottom]>.styles-module_arrow__K0L3T{transform:rotate(225deg)}[class*=react-tooltip__place-left]>.styles-module_arrow__K0L3T{transform:rotate(315deg)}.styles-module_dark__xNqje{background:var(--rt-color-dark);color:var(--rt-color-white)}.styles-module_light__Z6W-X{background-color:var(--rt-color-white);color:var(--rt-color-dark)}.styles-module_success__A2AKt{background-color:var(--rt-color-success);color:var(--rt-color-white)}.styles-module_warning__SCK0X{background-color:var(--rt-color-warning);color:var(--rt-color-white)}.styles-module_error__JvumD{background-color:var(--rt-color-error);color:var(--rt-color-white)}.styles-module_info__BWdHW{background-color:var(--rt-color-info);color:var(--rt-color-white)}
